import React from "react";
import parse from "html-react-parser";

const educationContent = [
  {
    year: "2023",
    degree: "Master of Business Management (MBA)",
    institute: "Australian Institute of Business",
    details: `
            MBA at AIB currently completed the following modules with distinction:
              <p>•	Strategic Management</p>
              <p>•	Leadership</p>
              <p>I will be completing the following modules over the next 12 months:</p>
              <p>•	Operations Management</p>
              <p>•	Financial Management</p>
              <p>•	Strategic HR Management</p>
              <p>•	Marketing Management</p>
              <p>•	Corporate Governance</p>
              <p>•	Business Analytics for Managers</p>
    `,
  },
  {
    year: "2022",
    degree: "Complete Machine Learning and Data Science",
    institute: "Zero To Mastery Academy",
    details: `Learn Data Science, Data Analysis, Machine Learning (Artificial Intelligence)
              and Python with Tensorflow, Pandas, NumPy, Matplotlib. Implement Machine Learning algorithms and
              how to improve Machine Learning Models.`,
  },
  {
    year: "2022",
    degree: "Complete Python Developer",
    institute: "Zero To Mastery Academy",
    details: `Master modern Python 3 fundamentals as well as advanced topics, Machine Learning with Python,
              Python to process: Images, CSVs, PDFs, and other Files.
              <p>•	Python Fundamentals and Advanced Topics: Covering syntax, data types, control flow, functions, OOP, decorators, and generators.</p>
              <p>•	Data Handling and Manipulation: Working with CSV, JSON, databases, and libraries like Pandas and Numpy for data analysis.</p>
              <p>•	Web Development and APIs: Using frameworks like Flask and Django, building RESTful APIs, and integrating frontend with backend.</p>
              <p>•	Debugging, and Deployment: Debugging techniques, and deploying applications using Docker and CI/CD pipelines.</p>
`,
  },
  {
    year: "2016",
    degree: "INTRO TO BUSINESS AND MANAGEMENT",
    institute: "The Open University (UK)",
    details: `This key introductory module provides an accessible and
              comprehensive introduction to business and management in a globalized world.
              Through readings and international case studies, there is a wide range of
              topics in contemporary business and management: what motivates people to work;
              how to assess financial soundness of a business; what attracts customers;
              how economic crises affect businesses; and ethical challenges in business and management.`,
  },
  {
    year: "2015",
    degree: "INFLUENCING SKILLS",
    institute: "rogenSi (Australia)",
    details: `RogenSi's Influencing Skills program provides a deeper understanding of the qualities of
     an effective influencer and the characteristics of influencing with or without authority across and
     upwards in your organization. Learning to influence internal stakeholders more effectively to embrace your
     ideas, accept your recommendations and support your initiatives.`,
  },
  {
    year: "2014",
    degree: "EXALEAD CLOUDVIEW FUNDAMENTS TRAINING",
    institute: "Dassault Systems (France)",
    details: `Fundamentals of Exalead Cloudview search engine software. This was a one on one
              training with their developers, architects and product owners in their Paris Head Office.`,
  },
  {
    year: "2013",
    degree: "ARCHITECTING AWS",
    institute: "Amazon Partner Training (Australia)",
    details: `In this course, architecting with AWS, you will learn how to design and use AWS services for
    common IT applications and how to take full advantage of cloud scalability and elasticity.
    You will learn and discuss established patterns and reference architectures to use in the cloud.`,
  },
];

const Education = () => {
  return (
    <ul>
      {educationContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-briefcase"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.degree}
            <span className="place open-sans-font">{val.institute}</span>
          </h5>
          <p className="open-sans-font">{parse(val.details)}</p>
        </li>
      ))}
    </ul>
  );
};

export default Education;
