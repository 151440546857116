import React from "react";

const Achievements = () => {
  return (
    <div className="row">
      <div className="col-3" key='blank'></div>
      <div className="col-6" key='aws'>
        <div className="box-stats with-margin achievements-center-image-text" style={{minHeight:'250px'}}>
          <img
            style={{width:'60px', height:'60px', border:'none'}}
            src={`img/aws.jpeg`}
            className="img-fluid main-img-mobile achievements-pd-b-10"
            alt="AWS"
          />
          <br></br>
          <h5 className="poppins-font position-relative">AWS Solutions Architecture</h5>
          Completed 2019
        </div>
      </div>



    </div>
  );
};

export default Achievements;
