import React from 'react';
import { useForm, ValidationError } from '@formspree/react';

const Contact = () => {
  const [state, handleSubmit] = useForm("mzbqzjgn");

  if (state.succeeded) {
      return <p>Thanks for Contacting Me, I will try an get back to you soon!</p>;
  }
  return (
    <form className="contactform" onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="form-group">
              <input
                id="email"
                type="email"
                name="email"
                placeholder="Your Email"
              />
              <ValidationError
                prefix="Email"
                field="email"
                errors={state.errors}
              />
            </div>
          </div>
          {/* End .col */}

          <div className="col-12">
            <div className="form-group">
              <textarea
                id="message"
                name="message"
                placeholder="Your Message"
              ></textarea>
            </div>
          </div>
          {/* End .col */}

          <div className="col-12">
            <button type="submit" className="button" disabled={state.submitting}>
              <span className="button-text">Send Message</span>
              <span className="button-icon fa-solid fa-paper-plane"></span>
            </button>
          </div>
          {/* End .col */}
        </div>
      </form>
  );
}

export default Contact;