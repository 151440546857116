import React from "react";
import parse from "html-react-parser";

const experienceContent = [
  {
    year: "April 2023 - Present",
    position: "Senior SRE",
    compnayName: "Nintex",
    image: "nintex.jpg",
    location: "Melbourne Australia",
    details: `
    My primary focus is developing and expanding the SRE function across the organization, encompassing a range of responsibilities essential for ensuring our infrastructure's reliability, efficiency, and scalability.
    Developing and expanding the SRE function across the organization, encompassing a range of responsibilities essential for ensuring our infrastructure's reliability, efficiency, and scalability.
    <br><br><p>Key Responsibilities and Achievements:</p>
      <p>•	Team Building and Management: Established and expanded a remote SRE team in South Africa, ensuring effective collaboration and high performance.
      <p>•	Multi-Region Kubernetes Clusters: Architected and deployed multi-region Kubernetes clusters using Terraform, ensuring high availability and disaster recovery capabilities.</p>
      <p>•	Observability and Monitoring: Developed and implemented an observability toolset for monitoring Azure resources and Kubernetes clusters using Prometheus/Grafana (Grafana, Loki, Mimir, Prometheus). This setup provides comprehensive visibility into system performance and health.</p>
      <p>•	Cost and Performance Optimization: Deployed cost and performance tools such as OpenCost, Azure Billing Exporter, and Goldilocks. These tools help with the provisioning and optimization of server infrastructure within our multi-region, multi-environment Kubernetes clusters in Azure.</p>
      <p>•	Terraform Advisory: Served as a Terraform advisor to the platform team, leveraging my decade-long experience to establish best practices and avoid anti-patterns. Provided guidance on efficient and scalable infrastructure as code (IaC) implementations.</p>
      <p>•	Architectural Standards and Governance: Participated in the Advisor/Architecture group to set standards for building infrastructure in Azure, including Privileged Identity Management (PIM), Virtual Networks (Vnets), Kubernetes clusters, Azure DevOps (ADO) Pipelines, and Key Vaults.</p>
      <p>•	Self-Service Deployment: Contributed to the development and management of a self-service deployment toolchain, including Backstage and customizations that allow development teams to build projects from standardized templates.</p>
      <p>•	Service Level Objectives (SLOs) and Error Budgets: Defined, set, and monitored SLOs, KPIs, and error budgets to ensure the reliability and performance of services.</p>
      <p>•	Security Dashboards: Developed security dashboards based on Microsoft Defender results, enhancing the security posture of our Kubernetes clusters.</p>
    <br><p>This role has allowed me to leverage my expertise in SRE practices, cloud infrastructure, and team management to drive significant improvements in our operational efficiency and system reliability. My contributions have been pivotal in building a robust and scalable infrastructure that supports our business goals.</p>
    `,
  },
  {
    year: "December 2020 - April 2023",
    position: "Self-Employed Consultant/Contractor",
    compnayName: "WonderPhil Tech LTD",
    image: "wonderphiltech.jpeg",
    location: "UK/US/Israel/Australia",
    details: `
    <p><b>Consulting for Nintex/Kyron (UK/US & Israel): Dec 2020 - April 2023</b></p>
    <p>•	CI/CD Pipeline Establishment: Transitioned from outdated, on-premises tools like TeamCity and Jira to modern, managed solutions such as Azure Pipelines and Azure DevOps (ADO). Implemented new CI/CD toolsets and trained development teams to extend, maintain, and operate these tools effectively.</p>
    <p>•	Security and Compliance Enhancement: Automated key and certificate rotation, resulting in substantial cost savings and freeing up significant DevOps resources. Improved system reliability and performance while enhancing operational efficiency.</p>
    <p>•	Security Vulnerability Management: Integrated Synk into new pipelines to ensure robust security vulnerability oversight.</p>
    <p>•	Continuous Improvement and Collaboration: Focused on training teams to operate and take ownership of solutions, fostering a culture of continuous improvement and collaboration.</p>
    <p>•	Key Project: Developed a .NET Function for automatic key rotation in Azure Key Vault, accessible via API Gateway for manual and automatic runs.</p>
    <p>•	Infrastructure as Code: Wrote Terraform modules to build Azure landscapes, including Vnets, Databases, Load Balancers, Kubernetes Clusters, Key Vaults, Resource Groups, and Storage accounts.</p>
    <br><p><b>Consulting for Home Installers (Global): Jan 2020 - March 2023</b></p>
    <p>•	CI/CD Pipeline Implementation: Designed and implemented a robust CI/CD pipeline for microservices-based software, optimizing the development cycle for rapid and efficient deployment.</p>
    <p>•	Security and Budgeting: Prioritized security and managed infrastructure budgeting to ensure market speed.</p>
    <p>•	Infrastructure as Code: Built out all Infrastructure as Code (IaC) using Terraform to establish Azure Infrastructure, including Storage accounts, Landing Zones, Kubernetes Clusters, Azure Networking, Azure Databases, and Key Vaults.</p>
    <p>•	Security Scanning: Integrated Synk and best practices into GitHub Actions for pipeline creation.</p>
    <br><p><b>Consulting for Taxi for Email (London): Sep 2021 - Jan 2022</b></p>
    <p>•	AWS Infrastructure Construction: Led a project to build new AWS infrastructure from the ground up, enabling three distinct environments.</p>
    <p>•	Elastic Beanstalk Migration: Transitioned an existing Ruby on Rails app to ECS/Docker using Terraform and AWS Pipelines.</p>
    <p>•	Platform Design: Designed a new platform to meet cost, project deadlines, technical requirements, and business needs.</p>
    <p>•	Team Training: Trained the development team to build, manage, and maintain the new platform.</p>
    <br><p><b>Consulting for TodayTix (London): Oct 2020 - Feb 2021</b></p>
    <p>•	Global Cloud Network Architecture: Architected a global cloud network in AWS to replace an outdated MPLS network and client VPNs using infrastructure-as-code.</p>
    <p>•	Business Integration: Ensured seamless integration of new acquisitions into the network, significantly enhancing operational efficiency, scalability, and security, supporting TodayTix's growth strategy.</p>

    `,
  },
  {
    year: "Apr 2020 - Oct 2020",
    position: " Director Of IT and Cloud",
    compnayName: "TodayTix",
    image: "ttg.jpeg",
    location: "London UK",
    details: `
      <p>As the Director of IT and Cloud at Encore Tickets, my role became particularly pivotal when TodayTix acquired the company. I was responsible for orchestrating the internal IT and DevOps transition, facilitating a seamless integration between the entities. This included:</p>
      <br>
      <p><b>Team and Department Restructuring:</b> Merging teams and restructuring departments to align with new organizational goals.</p>
      <p><b>Platform and Network Re-architecture:</b> Leading a comprehensive re-architecture of platforms and networks to support business continuity and efficiency.</p>
      <p><b>Business Documentation Collaboration:</b> Collaborating on business documentation to facilitate a successful business integration.</p>
      <p><b>Remote Work Transformation:</b> Transitioning the business model from office-based work to a remote working structure, especially during the Covid-19 pandemic.</p>

      <p>In this role, I balanced management responsibilities with hands-on DevOps work. My daily tasks involved working with tools like Ansible, Salt, and Terraform for configuration and infrastructure-as-code, and managing CI/CD processes using Jenkins and Bitbucket pipelines. A significant focus was migrating on-premise applications to AWS, leveraging AWS ECS (Docker).</p>

      <br><p><b>Key Technical Expertise:</b></p>
      <p><b>AWS Services:</b> Extensive experience with EC2, VPC, IAM, RDS, Route53, S3, Redis, Managed Elasticsearch Domains, WAF, Data Migration Services, Lambda, API Gateway, Transit Gateways, Site-to-Site VPNs, Glue, Lex, Kinesis, Redshift, Workspaces, and more.</p>
      <p><b>Configuration Management:</b> Transitioned from Salt and user data scripts to Ansible, including setting up AWX (Ansible Tower Free version).</p>
      <p><b>Infrastructure as Code:</b> Introduced Terraform, migrating from CloudFormation and building reusable modules to ensure security, tagging, high availability, and cost-efficiency.</p>
      <p><b>Cost Optimization:</b> Implementing spot instances for pre-prod environments to reduce costs.</p>
    `,
  },
  {
    year: "Jul 2018 - Apr 2020",
    position: "Head Of Cloud and IT Support",
    compnayName: "Encore Tickets",
    image: "encore.jpeg",
    location: "London UK",
    details: `
      <p>As the Director of IT and Cloud at Encore Tickets, I managed daily IT operations and led a team of DevOps engineers, ensuring the smooth functioning of over 300 e-commerce sites, generating annual revenues above £100 million. My role involved overseeing and optimizing IT infrastructure, driving productivity, and ensuring business continuity and disaster recovery.</p>

      <br><p><b>Key Responsibilities and Achievements:</b></p>
      <p><b>IT Operations Management:</b> Oversaw day-to-day IT operations and managed a team of DevOps engineers, ensuring that DevOps practices were deployed to support the smooth running of our extensive distribution network. This involved managing offshore, nearshore, and onshore teams.</p>
      <p><b>Strategic IT Leadership:</b> Worked closely with the CTO to overhaul existing IT operations, ensuring a fast, reliable, and secure service. This included bringing all current technology up to date and driving the business forward through continuous improvement and cloud migration.</p>
      <p><b>Service Desk Leadership:</b> Led the Service Desk, comprising 1st to 3rd line technicians, ensuring SLAs and KPIs were maintained while optimizing, automating, and de-risking the environment. Successfully improved service uptime from 99.80% to 99.995%.</p>
      <p><b>Cloud Infrastructure:</b> Spearheaded the migration of on-premise applications to AWS, utilizing a wide range of AWS services including EC2, VPC, IAM, RDS, Route53, S3, Redis, and many others. Implemented infrastructure-as-code using Terraform and Ansible, building reusable modules for security, tagging, high availability, and cost efficiency.</p>
      <p><b>Business Continuity and Disaster Recovery:</b> Developed and implemented vital business continuity and disaster recovery strategies, safeguarding data and network services.</p>
      <p><b>Stakeholder Collaboration:</b> Actively engaged with senior leadership and department heads to shape IT strategy and ensure alignment with business goals. Collaborated with C-level management and third-party partners to foster highly engaged teams, resulting in increased engagement scores from 53.7 to 75.7.</p>

      <br><p><b>Technical Expertise:</b></p>
      <p><b>DevOps Tools:</b> Experienced with Ansible, Salt, Terraform, Jenkins, and Bitbucket pipelines for configuration management and CI/CD processes.</p>
      <p><b>AWS Services:</b> Extensive experience with AWS ECS (Docker), EC2, VPC, IAM, RDS, Route53, S3, Redis, Managed Elasticsearch Domains, WAF, Data Migration Services, Lambda, API Gateway, Transit Gateways, Site-to-Site VPNs, Glue, Lex, Kinesis, Redshift, Workspaces, and more.</p>
      <p><b>Service Optimization:</b> Focused on optimizing services, mitigating risks, and maintaining a compliant infrastructure, ensuring high availability and cost efficiency.</p>
    `,
  },
  {
    year: "Oct 2016 - Jun 2018",
    position: "Head of Infra/DevOps",
    compnayName: "Broadbean Technology",
    image: "bean.jpeg",
    location: "London UK",
    details: `
    <p>As a key leader at Broadbean, I was entrusted with the significant responsibility of devising and executing a comprehensive, future-forward strategy for Broadbeans global infrastructure, including major global cloud transformation projects. My role encompassed both hands-on technical work and leadership, ensuring the smooth execution of critical projects and alignment with business objectives.</p>

    <br><p><b>Key Responsibilities and Achievements:</b></p>
    <p><b>Infrastructure Strategy and Roadmap:</b> Collaborated with key stakeholders across the UK, US, and Australian offices to design and implement a strategy and roadmap for future-proofing Broadbeans infrastructure. This included planning and leading the migration of around 60 applications and 300+ servers from multiple data centers to AWS.</p>
    <p><b>Data Center Migrations and Configuration Management:</b> Led data center migrations and transitions in configuration management, ensuring smooth and efficient operations.</p>
    <p><b>Infrastructure-as-Code and Centralized Logging:</b> Implemented infrastructure-as-code frameworks and new configuration management systems. Designed and implemented centralized logging, monitoring, and dashboarding tools using Elasticsearch, Kibana, Kinesis, Fluentd, Zabbix, CloudWatch, and Grafana, processing 1.2 billion documents a week.</p>
    <p><b>Regulatory Compliance:</b> Successfully implemented GDPR and OFAC (U.S. Economic and Trade Sanctions Policy) guidelines, ensuring compliance with regulatory mandates.</p>
    <p><b>DevOps Team Construction and Leadership:</b> Built the DevOps team from inception, mentoring and coaching developers, DevOps, and support staff across global offices. Transitioned the team towards Platform Engineering to enhance productivity and scalability.</p>
    <p><b>Service Optimization and Security:</b> Ensured best practices around security and data protection, including GDPR compliance. Implemented policies and frameworks to provide clear requirements for designing and building infrastructure for applications and services.</p>
    <p><b>Disaster Recovery and Business Continuity:</b> Defined and implemented a new disaster recovery framework to safeguard data and network services, ensuring business continuity.</p>
    <p><b>Technology and Tool Utilization:</b> Leveraged a diverse array of technologies including HashiCorp toolset, Ansible, Puppet, Zabbix, PRTG, Elasticsearch, Kinesis, AWS, Mongo Cloud, Docker, Perl, Java, Ruby, Python, Node.js, Netflix toolset, Jenkins, and Git for effective infrastructure management and enhancement.</p>

    <br><p><b>Innovative Initiatives:</b></p>
    <p><b>Hack Days and Cost Management:</b> Initiated hack days to foster innovation, including projects like a team-based cost management portal for AWS resources.</p>
    <p><b>Service Discovery and Automation:</b> Implemented Service Discovery, Security Monkey, and other Netflix tools to enhance automation and operational efficiency.</p>

    `,
  },
  {
    year: "Nov 2015 - Oct 2016",
    position: "Technical Implementations Lead",
    compnayName: "Agilisys",
    image: "agilisys.jpeg",
    location: "London UK",
    details: `
    As the Technical Implementation Lead at Agilisys Digital, I have the privilege of leading a diverse, skilled team, comprising individuals from various IT backgrounds. My team, a high-performing unit of eight, includes Application Support personnel, Developers, QA Testers, Apprentices, and Business Analysts.
    <br><br>
    In my role, I am accountable for delivering Agilisys Digital Solutions to our clients. This task requires extensive cross-functional collaboration. I work in close conjunction with various stakeholders including, but not limited to, Project Managers, Development and Infrastructure teams, Product teams, third-party partners, clients, and other business units within Agilisys.
    <br><br>
    A significant part of my duties involves ensuring the smooth delivery and daily operations of client sites. My focus remains on meeting all Service Level Agreements (SLAs) associated with client sites, and on regularly reviewing service performance metrics to identify and implement necessary improvements.
    <br><br>
    Additionally, I work closely with Solution/Technical Architects to design and develop infrastructure solutions that are not only scalable and reliable but also security-compliant, especially with regards to IL3/4 related frameworks. These solutions are designed to seamlessly align with the broader group and business system strategies.
    <br><br>
    Possessing a robust technical background, I have a comprehensive understanding of an array of technical concepts and architectures. This is complemented by my experience with business change management, enabling me to manage the intersection of technology and business with proficiency.
    `,
  },
  {
    year: "Jul 2013 - Oct 2015",
    position: "Senior DevOps Engineer",
    compnayName: "Seek",
    image: "seek.jpeg",
    location: "Melbourne Australia",
    details: `
    <p>SEEK is a conglomerate of diverse companies with a robust portfolio spanning employment, education, and volunteer sectors across Australia, New Zealand, Asia, South America, and Africa.</p>
    <br><p>In my capacity as a DevOps and Senior DevOps Engineer at SEEK, I function within agile environments, championing the transition of SEEK towards a more integrated DevOps approach. My involvement spans across a variety of transformational projects, including:</p>
    
    <br><p>Migration:</p>
    * Transitioned deployments from PowerShell scripts to automated deployment tools, including IBM uDeploy and Octopus Deploy.
    <br>* Migrated code repositories from Microsoft TFS to Atlassian's Stash and GitHub.
    <br>* Shifted builds from Microsoft's TFS to Team City.
    <br>* Upgraded monitoring software from HP SiteScope to PRTG Network Monitoring.
    <br>* Replaced Fast Search Engineer with Exalead CloudView.
    
    <br><br><p>Building:</p>
    * Developed self-service Development/QA environments in Amazon AWS that mimic production scenarios.
    
    <br><br><p>Architecture and Implementation:</p>
    * Designed and executed the establishment of production and sandbox environment platforms in Amazon AWS.
    <br>* Incorporated the Imperva Web Application Firewall into Amazon AWS.
    
    <br><br><p>Cultural Change:</p>
    * Actively participated in instigating a culture shift empowering developers with greater control, access, and responsibility. This included the introduction of the "builders are owners" philosophy.

    <br><br><p>Disaster Recovery:</p>
    * Crafted a geographically diversified disaster recovery process for production services.

    <br><br><p>Training and Mentoring:</p>
    * Routinely engaged in the training and mentoring of developers, operational staff, and other DevOps team members.
    
    <br><br><p>In these efforts, I leveraged my technical acumen and leadership capabilities to foster operational excellence and contribute to SEEK's strategic objectives.</p>
    `,
  },
  {
    year: "Apr 2012 - Jul 2013",
    position: "Release Engineer",
    compnayName: "Object Consulting",
    image: "object.jpg",
    location: "Melbourne Australia",
    details: `
    <p>As a consultant to Seek, I was initially engaged as a Release Engineer, a role that predated the company's transition towards embracing DevOps practices.</p>
    <br><p>My responsibilities were manifold, including:</p>
    * Collaboration: I worked closely with various business stakeholders on internal projects, ensuring successful product releases through comprehensive project management and effective coordination.
    <br><br>* Configuration: I was involved in both general and advanced configuration tasks related to Microsoft Internet Information Services, optimizing the functionality of this service to meet business needs.
    <br><br>* Script Development: I developed deployment scripts using PowerShell, streamlining and automating tasks to enhance operational efficiency.
    <br><br>* Deployment: My role also encompassed deploying web applications and services across various environments, including Development, Integration, Staging, and Production, guaranteeing smooth transitions and minimal disruption.
    <br><br>* Installations: I handled BizTalk 2010 installations, ensuring seamless integration and functioning within the existing infrastructure.
    <br><br>* Cluster Management: My duties also extended to Microsoft Cluster Management, wherein I performed installations and configurations, fortifying the resilience and reliability of our systems.
    <br><br>* Support: I provided ongoing support for vSphere versions 4.x and 5.x, ensuring optimal performance and troubleshooting any issues that arose.

    <br><br>In each of these capacities, I utilized my technical expertise to ensure smooth operations, streamlined processes, and enhanced system performance, contributing to the overall success of Seek's technology strategy.
`,
  },
  {
    year: "Oct 2011 - Apr 2012",
    position: "Operations and Environment Specialist",
    compnayName: "Object Consulting",
    image: "object.jpg",
    location: "Melbourne Australia",
    details: `
    <p>During my consultancy period with News Corp, a comprehensive media organization offering advertising across print, web, broadcast, mobile, and tablet platforms, my role encompassed the following responsibilities:</p>
    <br>* Client Interaction: I operated within a client-focused environment, ensuring the stability and ongoing operation of the project environments, thereby maintaining the reliability of service delivery.
    <br><br>* Vendor Collaboration: I collaborated closely with third-party vendors, securing the successful execution of various projects through effective coordination and cooperation.
    <br><br>* Monitoring System Design & Implementation: I designed and implemented a monitoring system for Windows Azure using SCOM 2007 R2. This involved configuring various aspects such as:
    <br>* Email Notifications
    <br>* Alerts
    <br>* Monitors
    <br>* Rules
    <br>* Management Packs
    <br>* User Security
    <br>* Reporting Services
    <br><br>* Implementation Responsibilities: My duties also included implementing:
    <br>* Production, integration, and performance environments in Windows Azure
    <br>* SQL 2007 R2, SQL Azure, and SQL DataSync Azure
    <br>* Backups of Windows Server 2008 R2 and SQL 2007 R2
    <br>* Akamai ESI Test Servers on the Linux platform
    <br>* Autoscaler for Windows Azure using the Wasabi Application Block
    <br><br>* Script Writing: I wrote PowerShell scripts and configuration files using XML, JSON, thus automating processes and enhancing system functionality.
    <br><br>* Certificate Management: I oversaw the creation and management of security certificates, thereby strengthening system security.
    <br><br>* Documentation and Reporting: I documented all key aspects of the project and regularly reported to clients on the state of the environments. This ensured transparency, client satisfaction, and timely resolution of any potential issues.
    `,
  },
  {
    year: "Apr 2011 - Oct 2011",
    position: "System Engineer",
    compnayName: "RACQ",
    image: "racq.jpeg",
    location: "Brisbane Australia",
    details: `
    <p>The Royal Automobile Club of Queensland provides a wide range of motoring, travel and other services and benefits to members.</p>
    <br><p>Role included:</p>
    * Managed relationships with vendors and 3rd parties
    <br>* Built servers on physical and virtual hardware
    <br>* Performed maintenance and monitoring of systems with the use of SCOM
    <br>* Architected and implemented of backup systems using Acronis backup software
    <br>* Administration of Active Directory, Exchange and VCenter, SAN Administration using HP and Brocade hardware, HP DL, ML Servers and HP C Class Blade Hardware
    <br>* Created VB and PowerShell scripts to complete common tasks
    <br>* Log all calls into ITSM system and use systems to meet SLA’s
    <br>* Provide weekly status reports to manager and phone support
    <br>* Worked with other teams to escalate problems required to be fixed by 3rd parties and other teams within the business.
    <br>* Perform support remotely`,
  },
  {
    year: "Aug 2010 - Apr 2011",
    position: "Field Network Administrator",
    compnayName: "Data3 - Contracted out to Queensland Gas Company(QGC)",
    image: "qgc.jpeg",
    location: "Brisbane/Chinchilla Australia",
    details: `
    <p>QGC, a subsidiary of British Gas, initiated the setup of gas fields and pipelines throughout Western Queensland. My association with this expansive endeavor was in the capacity of Network Administrator.</p>
    <br><p>In this role, I was entrusted with the responsibility of managing the networks across all remote offices in Queensland. My mandate was to ensure seamless connectivity back to the central hub at the head office in Brisbane. The position demanded robust technical expertise, problem-solving acumen, and diligent maintenance of network infrastructure.</p>
    <br><p>This role necessitated operating in a Fly-in Fly-out (FIFO) arrangement, underscoring my capacity to adapt and function effectively in challenging environments while upholding the highest standards of network integrity and performance.</p>
    `,
  },
  {
    year: "Aug 2009 - Aug 2010",
    position: "Senior IT Support Engineer",
    compnayName: "Data3 - Contracted out to Queensland Gas Company(QGC)",
    image: "qgc.jpeg",
    location: "Brisbane/Chinchilla Australia",
    details: `
    <p>QGC, a subsidiary of British Gas, embarked on the establishment of gas fields and pipelines throughout Western Queensland. During this period, my responsibilities were crucial to the effective functioning of the business units in the region's remote offices.</p>
    <br><p>As the primary support liaison, I ensured seamless business operations by providing first-line support to all units, addressing a myriad of technical and operational challenges. This role required me to operate in a Fly-in Fly-out (FIFO) capacity, highlighting my adaptability and resilience in challenging work environments while maintaining the highest standards of service delivery.</p>
    `,
  },
  {
    year: "Apr 2009 - Aug 2009",
    position: "IT Consultant",
    compnayName: "Trampolines Australia",
    image: "",
    location: "Brisbane Australia",
    details: `Trampolines Australia is a national company based in Wakerley, Brisbane, Australia. It boasts the finest trampoline institution in Australia.`,
  },
  {
    year: "Sep 2008 - Apr 2009",
    position: "IT support Analyst",
    compnayName: "Arup",
    image: "arup.jpeg",
    location: "Brisbane Australia",
    details: `Arup is a global firm of designers, engineers, planners and business consultants providing a diverse range of professional services to clients around the world. Arup has over 10 000 staff working in more than 90 offices in 37 countries. At any one time, they have over 10 000 projects running concurrently.`,
  },
  {
    year: "May 2008 - Sep 2008",
    position: "Service Desk Operator",
    compnayName: "Suncorp Bank",
    image: "suncorp.jpeg",
    location: "Brisbane Australia",
    details: `Suncorp is one of Australia's leaders in insurance, investment, superannuation and banking employing over 17,000 people. In this role I was apart of team that worked as call center for the help desk fuinction of Suncorp corp IT.`,
  },
  {
    year: "Oct 2007 - May 2008",
    position: "Microsoft Office 2007 Specialist",
    compnayName: "Suncorp Bank",
    image: "suncorp.jpeg",
    location: "Brisbane Australia",
    details: `• Assist with the Project planning and development for the deployment of Office 2007 to over 20,000 PC in Australia and New Zealand.`,
  },
  {
    year: "Jan 2007 - Oct 2007",
    position: "On-Site Computer & Network Technician",
    compnayName: "ChoiceTel",
    image: "",
    location: "Brisbane Australia",
    details: `Choicetel offer all-in one solution for all your communication media needs. Covering computers, mobiles and internet/networks.`,
  },
  {
    year: "Jan 2006 - Dec 2006",
    position: "Computer Tech and Sales",
    compnayName: "I-Central",
    image: "",
    location: "Brisbane Australia",
    details: `I-Central was one of the busiest computer shops situated in the centre of Brisbane city. Offering people of Brisbane city the best of their computer needs.`,
  },
];

const Experience = () => {
  return (
    <ul>
      {experienceContent.map((val, i) => (
        <li key={i}>
          {val.image === "" && (
            <div className="icon">
              <i className="fa fa-briefcase"></i>
            </div>
          )}
          {val.image !== "" && (
            <div className="icon">
              <img
                style={{ width: "40px", height: "40px", border: "none" }}
                src={`img/companies/${val.image}`}
                className="img-fluid main-img-mobile d-sm-block "
                alt="hero man"
              />
            </div>
          )}
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="experience poppins-font text-uppercase">
            <span className="position-color">{val.position}</span>
            <span className="place open-sans-font">{val.compnayName}</span>
          </h5>
          <h6>
            <span className=" open-sans-font">{val.location}</span>
          </h6>
          <p className="open-sans-font">{parse(val.details)}</p>
        </li>
      ))}
    </ul>
  );
};

export default Experience;
