import React from "react";
import Experience from "./Experience";

const index = () => {
  return (
    <section className="main-content ">
      <div className="container">
        {/* Experience Starts */}
        <div className="row">
          <div className="col-12">
            <h3 className="text-uppercase pb-5 mb-0 text-left text-sm-center custom-title ft-wt-600">
              About
            </h3>
            <p className="pb-5 mb-0 text-left text-sm-center">
              In the last three years, I have strategically stepped back to roles with less responsibility to prioritize my family's needs, including working part-time due to the birth of both my children. Having successfully balanced my personal and professional life, I am now eager to seek my next leadership role, where I can leverage my extensive experience in engineering and platforms to drive technological transformation, enhance productivity, and deliver cutting-edge solutions.
            </p>
            <p className="pb-5 mb-0 text-left text-sm-center">
              I bring over 15 years of progressive experience in technology leadership roles, with significant tenures at TodayTix/Encore Tickets and Broadbean.
            </p>
            <p className="pb-5 mb-0 text-left text-sm-center">
              Currently, I am pursuing an MBA and have completed modules in Leadership and Strategic Management with Distinctions and currently working on Operations.
            </p>
            <p className="pb-5 mb-0 text-left text-sm-center">
              My unique blend of hands-on technical proficiency and leadership prowess ensures that I deliver operational excellence while focusing on strategic objectives. Known for building strong relationships with C-level stakeholders, I excel at understanding their vision and translating it into actionable technology strategies.
            </p>
          </div>
          <div className="col-lg-12 m-15px-tb">
            <div className="resume-box">
              <Experience />
            </div>
          </div>
        </div>
        {/*  Experience Ends */}
      </div>
    </section>
  );
};

export default index;
