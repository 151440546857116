import React from "react";
import Education from "./Education";
import Achievements from "./Achievements";


const index = () => {
  return (
    <section className="main-content ">
      <div className="container">
        {/* education Starts */}
        <div className="row">
          <div className="col-12">
            <h3 className="text-uppercase pb-5 mb-0 text-left text-sm-center custom-title ft-wt-600">
            Education
            </h3>
          </div>
          <div className="col-lg-12 m-15px-tb">
            <div className="resume-box">
              <Education />
            </div>
          </div>
        </div>
        {/*  education Ends */}
        <hr className="separator" />
        <div className="row">
          <div className="col-12">
            <h3 className="text-uppercase pb-5 mb-0 text-left text-sm-center custom-title ft-wt-600">
              Certifications
            </h3>
          </div>
          {/*  Boxes Starts */}
          <div className="col-xl-12 col-lg-12 col-12 mt-12 mt-lg-0">
            <Achievements />
          </div>
          {/* Achievements Ends */}
        </div>
      </div>
    </section>
  );
};

export default index;
